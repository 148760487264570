import { isPercentage } from "src/utils/utils";

const Logo = ({ width = 150, height }) => {

    let renderHeight = height;
    let isWidthPercentage = isPercentage(width);
    if (!isWidthPercentage) {
        if (!renderHeight) {
            renderHeight = width / (150 / 44);
        } else if (!width || !height) {
            renderHeight = 44;
        }
    }

    return (

        <svg width={width} height={renderHeight} viewBox="0 0 237 92" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 64.4483V16.5879H10.1734V64.4483H0Z" fill="#FF8B00" />
            <path d="M42.432 16.6133V64.4737H32.1395L26.898 40.5435V64.4737H16.6055V16.6133H27.4221L32.1872 39.4906V16.6133H42.432Z" fill="#FF8B00" />
            <path d="M48.8652 64.4492V16.5887H63.3748C64.9394 16.4487 66.5158 16.6452 67.9989 17.1651C69.4819 17.6851 70.8377 18.5165 71.9757 19.6039C73.8855 21.9226 74.8553 24.879 74.6918 27.8838V50.2346C74.978 54.0053 73.9965 57.7635 71.9042 60.9075C70.7847 62.1525 69.3932 63.1201 67.839 63.7346C66.2848 64.349 64.61 64.5936 62.946 64.4492H48.8652ZM59.4436 25.084V55.7147H61.2782C63.2795 55.7147 64.304 54.8771 64.304 53.178V29.535C64.4299 28.2787 64.1365 27.0161 63.4701 25.9455C63.0929 25.5835 62.6418 25.3082 62.1481 25.1388C61.6544 24.9694 61.13 24.9099 60.6111 24.9643L59.4436 25.084Z" fill="#FF8B00" />
            <path d="M80.6006 64.4503V16.5898H90.7501V64.4503H80.6006Z" fill="#FF8B00" />
            <path d="M117.006 16.6133L122.271 64.4737H112.098L111.359 57.2946H106.046L105.379 64.4737H95.1582L100.543 16.6133H117.006ZM109.144 25.5871H108.214L106.809 48.5362H110.597L109.144 25.5871Z" fill="#FF8B00" />
            <path d="M135.304 64.4483V16.5879H156.365V25.8728H146.001V35.2056H155.865V44.1794H146.001V55.0916H157.056V64.4244L135.304 64.4483Z" fill="#00AF53" />
            <path d="M180.904 16.6133L186.17 64.4737H175.996L175.258 57.2946H169.945L169.278 64.4737H159.057L164.441 16.6133H180.904ZM173.042 25.5871H172.089L170.683 48.5362H174.448L173.042 25.5871Z" fill="#00AF53" />
            <path d="M186.051 16.6133H209.066V25.8264H202.824V64.4498H192.293V25.8264H186.051V16.6133Z" fill="#00AF53" />
            <path d="M224.719 64.9997C219.835 64.9997 216.356 63.875 214.26 61.6255C211.949 58.6846 210.834 54.9736 211.139 51.2398V46.4538H221.55V50.7612C221.55 53.8482 222.384 55.3797 224.052 55.3797C224.41 55.3982 224.766 55.3242 225.087 55.165C225.408 55.0059 225.684 54.7667 225.887 54.4704C226.376 53.7724 226.619 52.9301 226.578 52.0774C226.598 50.9776 226.421 49.8831 226.053 48.8468C225.674 47.9206 225.134 47.0697 224.457 46.3341C223.37 45.1665 222.208 44.0713 220.979 43.0557L216.523 39.179C214.898 37.9405 213.571 36.3515 212.639 34.5292C211.707 32.7068 211.195 30.6976 211.139 28.6497C210.913 26.9745 211.069 25.2696 211.595 23.6638C212.121 22.0581 213.004 20.5936 214.177 19.3811C215.35 18.1685 216.782 17.2397 218.365 16.6647C219.948 16.0898 221.64 15.8838 223.313 16.0624H225.172C226.452 16.1209 227.719 16.3543 228.936 16.7564C230.454 17.1864 231.854 17.9554 233.034 19.0058C234.313 20.1913 235.244 21.7058 235.726 23.385C236.367 25.6159 236.664 27.932 236.608 30.253H225.934V27.3574C225.895 26.8077 225.648 26.2938 225.243 25.9216C225.052 25.7225 224.823 25.5646 224.569 25.4576C224.315 25.3506 224.042 25.2968 223.766 25.2995C223.118 25.2818 222.488 25.5128 222.003 25.9456C221.763 26.146 221.571 26.399 221.443 26.6852C221.315 26.9714 221.254 27.2832 221.264 27.5968C221.406 28.5095 221.743 29.3805 222.254 30.1491C222.764 30.9177 223.434 31.5656 224.219 32.0478L230.604 37.2167C231.568 37.9967 232.468 38.853 233.296 39.7772C233.998 40.5706 234.613 41.437 235.131 42.3617C235.583 43.2232 235.941 43.9889 236.227 44.7547C236.498 45.4814 236.69 46.2357 236.799 47.0042C236.918 47.8178 236.965 48.416 236.989 48.8468C237.013 49.2775 236.989 49.7801 236.989 50.4501C237.004 51.9794 236.9 53.5075 236.679 55.0208C236.447 56.3864 236.055 57.7197 235.512 58.9932C234.998 60.195 234.244 61.2782 233.296 62.1759C232.274 63.092 231.073 63.7847 229.77 64.21C228.14 64.744 226.434 65.0107 224.719 64.9997Z" fill="#00AF53" />
            <g clip-path="url(#clip0_1938_4994)">
                <path d="M237 73H0V92H237V73Z" fill="#132328" />
                <path d="M21.2555 86.9692H20.9696C18.5867 86.9692 18.1101 86.3958 17.5382 85.3794C16.9664 84.3629 16.7996 83.6853 16.5136 82.9555C16.2277 82.2257 16.1085 82.1736 15.5843 82.1736H15.1077L14.6311 84.7799C14.3929 86.0831 14.6312 86.2134 15.8941 86.3437V86.9431H10.5088V86.3437C11.9147 86.0309 12.153 86.0309 12.3913 84.7017L13.7018 78.212C13.9878 76.8828 13.7018 76.8046 12.4627 76.7264L12.6057 76.127H17.3714C19.8973 76.127 21.0887 77.1955 21.0887 78.7333C21.0887 80.271 19.7781 81.3396 18.3723 81.7566C18.7059 82.5906 19.0633 83.3986 19.3492 84.0241C20.35 86.0309 20.7789 86.2655 21.4223 86.4479L21.2555 86.9692ZM15.7273 81.4699C17.5859 81.4699 18.6582 80.5577 18.6582 78.8636C18.6818 78.6069 18.6554 78.3477 18.5807 78.103C18.506 77.8583 18.3847 77.6337 18.2248 77.4441C18.065 77.2544 17.8701 77.104 17.6532 77.0027C17.4363 76.9015 17.2022 76.8517 16.9664 76.8567C16.466 76.8567 16.18 76.8567 16.037 77.7168L15.2745 81.4699H15.7273Z" fill="white" />
                <path d="M28.8569 87.022C28.5462 87.0516 28.2336 87.0025 27.9432 86.8783C27.6528 86.7541 27.3925 86.5583 27.1827 86.3061C26.9728 86.0538 26.8191 85.752 26.7333 85.4241C26.6476 85.0962 26.6322 84.7511 26.6885 84.4157C26.6958 83.6153 26.8716 82.8275 27.2022 82.1133C27.5327 81.3992 28.0093 80.7779 28.5947 80.2977C29.4153 79.6921 30.3705 79.3408 31.3589 79.2812C32.6218 79.2812 33.1937 79.9589 33.1937 80.7147C33.1937 82.4609 30.5725 83.1386 28.8569 83.321C28.8459 83.5294 28.8459 83.7382 28.8569 83.9465C28.8569 84.8066 29.119 85.5625 30.0483 85.5625C31.0172 85.492 31.9269 85.027 32.598 84.2593L32.8839 84.8066C32.3689 85.4374 31.7422 85.9474 31.0419 86.3058C30.3415 86.6642 29.582 86.8635 28.8092 86.8917M28.9522 82.6955C30.7393 82.2785 31.335 81.5748 31.335 80.6887C31.3459 80.5853 31.3357 80.4807 31.3052 80.3822C31.2747 80.2837 31.2245 80.1939 31.1585 80.1191C31.0924 80.0443 31.012 79.9865 30.923 79.9498C30.8341 79.9131 30.7388 79.8984 30.644 79.9068C30.1913 79.9068 29.3096 80.428 28.976 82.6955" fill="white" />
                <path d="M46.8234 85.1461C46.0903 86.1441 45.047 86.812 43.8925 87.0227C43.3206 87.0227 43.0347 86.5535 43.3445 84.9897V84.4164C42.8033 85.1457 42.1694 85.7864 41.462 86.319C40.9853 86.7528 40.39 87.0004 39.7701 87.0227C39.0553 87.0227 38.4834 86.3711 38.4834 85.0158C38.507 84.1682 38.7007 83.3367 39.0507 82.58C39.4007 81.8234 39.8985 81.1601 40.5088 80.6372C41.5529 79.7709 42.8206 79.2874 44.1308 79.2559C44.9299 79.31 45.7255 79.4144 46.5137 79.5686C46.1801 80.8457 45.6082 83.3738 45.3699 84.7812C45.3699 85.1982 45.3699 85.4328 45.3699 85.4328C45.7921 85.2965 46.1715 85.0348 46.466 84.677L46.8234 85.1461ZM43.1538 83.8169C43.5028 83.3618 43.7551 82.8277 43.8925 82.2531C44.0355 81.5755 44.2499 80.7154 44.3214 80.2723C43.9721 80.1221 43.6007 80.0427 43.2253 80.0378C41.6288 80.0378 40.8424 82.8004 40.8424 84.5467C40.8424 85.2243 41.0092 85.511 41.2714 85.511C41.5335 85.511 42.2722 85.0158 43.1062 83.8169" fill="white" />
                <path d="M56.1637 85.2231C55.407 86.2029 54.3465 86.8432 53.1852 87.0214C52.6133 87.0214 52.2558 86.6826 52.5894 85.1188L54.2574 77.2999C54.4242 76.5701 54.2574 76.4919 53.6856 76.4398H53.1852L53.352 75.9185C54.2263 75.7772 55.0933 75.5858 55.9493 75.3452C56.1403 75.2676 56.3414 75.2236 56.545 75.2148C56.7595 75.2148 56.7833 75.3973 56.688 75.7882C56.0208 78.7334 55.1391 82.8513 54.7102 84.8321C54.7102 85.2491 54.7102 85.4055 54.7102 85.4055C55.1271 85.2838 55.5059 85.0406 55.8063 84.7018L56.1637 85.2231Z" fill="white" />
                <path d="M81.0172 75.6055C80.8266 76.2831 80.6121 77.6384 80.4453 78.6288H79.7781C79.812 78.2387 79.812 77.846 79.7781 77.4559C79.7781 76.8826 79.3492 76.648 78.2531 76.648H77.2523L75.4652 84.7276C75.2269 85.9265 75.2983 86.161 76.9187 86.2392L76.7757 86.8387H71.176L71.319 86.2392C72.7963 86.2392 72.9393 85.9004 73.1776 84.7276L74.7741 76.9086H74.0593C72.7725 76.9086 72.4627 77.0911 72.1053 77.5602C71.7884 77.9574 71.5164 78.395 71.2951 78.8634H70.6279C70.975 77.7978 71.2615 76.7097 71.4858 75.6055H71.9385C72.1291 75.9443 72.2959 75.9443 72.7725 75.9443H79.6352C79.7768 75.9485 79.9177 75.9206 80.0489 75.8623C80.1801 75.804 80.2989 75.7165 80.3977 75.6055H81.0172Z" fill="white" />
                <path d="M91.5732 85.1461C90.8401 86.1441 89.7968 86.812 88.6423 87.0227C88.0704 87.0227 87.7845 86.5535 88.0943 84.9897L88.1657 84.4164C87.6246 85.1457 86.9907 85.7864 86.2833 86.319C85.8066 86.7528 85.2112 87.0004 84.5914 87.0227C83.8766 87.0227 83.3047 86.3711 83.3047 85.0158C83.3109 84.1806 83.4824 83.3568 83.8074 82.6009C84.1323 81.8451 84.603 81.1752 85.1872 80.6372C86.2313 79.7709 87.4989 79.2874 88.8091 79.2559C89.6082 79.31 90.4038 79.4144 91.192 79.5686C90.8584 80.8457 90.2865 83.3738 90.0482 84.7812C90.0482 85.1982 90.0482 85.4328 90.0482 85.4328C90.4704 85.2965 90.8498 85.0348 91.1443 84.677L91.5732 85.1461ZM87.9036 83.8169C88.2526 83.3618 88.5049 82.8277 88.6423 82.2531C88.7853 81.5755 88.9997 80.7154 89.0712 80.2723C88.7219 80.1221 88.3505 80.0427 87.9751 80.0378C86.3786 80.0378 85.5922 82.8004 85.5922 84.5467C85.5922 85.2243 85.759 85.511 86.0212 85.511C86.2833 85.511 87.022 85.0158 87.856 83.8169" fill="white" />
                <path d="M102.654 80.3238C102.635 80.5432 102.561 80.7525 102.438 80.9276C102.316 81.1028 102.151 81.2368 101.963 81.3142C101.748 81.3142 101.629 81.3142 101.391 81.3142C101.152 81.3142 100.438 80.402 99.9372 80.402C99.4368 80.402 99.2223 80.7668 99.2462 81.0796C99.27 81.3924 99.7942 81.8615 100.438 82.3306C101.438 83.0083 102.177 83.4774 102.177 84.3896C102.177 85.9013 99.7942 87.1523 98.6028 87.1523C97.4114 87.1523 96.6488 86.5789 96.625 85.8231C96.6518 85.5718 96.745 85.3344 96.8931 85.14C97.0412 84.9457 97.2378 84.8026 97.459 84.7284C97.602 84.7284 97.7211 84.7284 97.8879 84.9109C98.3602 85.5794 99.053 86.0192 99.818 86.1358C99.9076 86.1466 99.9982 86.1374 100.084 86.1089C100.171 86.0803 100.251 86.0329 100.32 85.9696C100.389 85.9063 100.445 85.8283 100.486 85.7404C100.527 85.6525 100.551 85.5565 100.557 85.4582C100.557 85.0933 100.39 84.8066 99.5083 84.1811C98.6266 83.5556 97.6973 82.9561 97.6973 81.9136C97.7294 81.5313 97.8488 81.1639 98.0445 80.8452C98.2402 80.5264 98.5059 80.2665 98.8172 80.0892C99.4722 79.5983 100.24 79.3184 101.033 79.2812C101.939 79.2812 102.63 79.7504 102.63 80.3238" fill="white" />
                <path d="M112.948 80.1944H111.304C110.494 83.9475 110.518 83.7389 110.351 84.6772C110.184 85.6155 110.351 85.433 110.351 85.433C110.97 85.3671 111.55 85.073 111.995 84.599L112.257 85.0942C111.476 86.1446 110.361 86.8335 109.136 87.0229C108.326 87.0229 107.992 86.4495 108.397 84.6512L109.374 80.2986H107.944V80.1422L108.469 79.4646H109.612L109.994 77.7444C110.686 77.4166 111.355 77.0333 111.995 76.5977L112.257 76.754C112.067 77.3535 111.733 78.6045 111.542 79.3603H113.377L112.948 80.1944Z" fill="white" />
                <path d="M119.93 87.0208C119.619 87.0504 119.307 87.0013 119.016 86.8771C118.726 86.7529 118.466 86.5571 118.256 86.3048C118.046 86.0526 117.892 85.7508 117.807 85.4229C117.721 85.095 117.705 84.7499 117.762 84.4145C117.762 83.5897 117.942 82.777 118.286 82.043C118.63 81.309 119.128 80.6747 119.739 80.1922C120.56 79.5866 121.515 79.2354 122.504 79.1758C123.767 79.1758 124.338 79.8534 124.338 80.6093C124.338 82.3555 121.717 83.0331 120.002 83.2156C119.991 83.4239 119.991 83.6327 120.002 83.8411C120.002 84.7012 120.264 85.457 121.193 85.457C122.162 85.3865 123.072 84.9216 123.743 84.1538L124.029 84.7012C123.514 85.3319 122.887 85.8419 122.187 86.2003C121.486 86.5587 120.727 86.758 119.954 86.7862M120.097 82.59C121.884 82.173 122.48 81.4693 122.48 80.5832C122.491 80.4799 122.48 80.3752 122.45 80.2767C122.419 80.1782 122.369 80.0884 122.303 80.0136C122.237 79.9388 122.157 79.881 122.068 79.8443C121.979 79.8076 121.884 79.7929 121.789 79.8013C121.336 79.8013 120.454 80.3226 120.121 82.59" fill="white" />
                <path d="M145.546 81.965C145.555 82.8675 145.338 83.7556 144.918 84.5324C144.498 85.3092 143.891 85.9447 143.163 86.3697C142.425 86.7847 141.609 87.0077 140.78 87.0213C140.446 87.0617 140.107 87.0244 139.787 86.9116C139.467 86.7989 139.173 86.6133 138.923 86.3672C138.673 86.121 138.473 85.8198 138.338 85.4833C138.202 85.1468 138.133 84.7827 138.135 84.4149C138.145 83.361 138.459 82.3375 139.033 81.4904C139.606 80.6433 140.409 80.0159 141.328 79.6975C141.853 79.4632 142.407 79.314 142.972 79.2544C143.301 79.2256 143.631 79.2716 143.943 79.3896C144.254 79.5076 144.54 79.695 144.782 79.94C145.023 80.185 145.216 80.4821 145.348 80.8127C145.479 81.1434 145.547 81.5002 145.546 81.8608M140.327 84.3107C140.327 85.5878 140.732 86.1872 141.399 86.1872C142.591 86.1872 143.282 83.7894 143.282 81.7565C143.282 80.4534 142.877 79.906 142.257 79.906C140.732 79.906 140.327 83.0857 140.327 84.3107Z" fill="white" />
                <path d="M152.933 79.4631C153.146 78.3128 153.709 77.2751 154.529 76.518C155.282 75.7587 156.248 75.2995 157.269 75.2148C158.223 75.2148 158.604 75.5537 158.604 75.9446C158.512 76.3938 158.264 76.7864 157.913 77.0393C157.722 77.0393 157.603 77.1696 157.46 77.0393C157.15 76.6101 156.711 76.3147 156.221 76.2052C155.816 76.2052 155.506 76.3877 155.006 79.5153H156.864L156.483 80.245L154.887 80.2971C154.624 81.7827 154.434 83.138 153.981 85.3273C153.844 86.2694 153.526 87.1692 153.05 87.9685C152.574 88.7677 151.949 89.4485 151.217 89.9666C150.676 90.4574 150.011 90.7572 149.311 90.8266C148.644 90.8266 148.072 90.4357 148.095 89.9926C148.127 89.7714 148.201 89.5598 148.312 89.3713C148.423 89.1829 148.568 89.0217 148.739 88.898C148.789 88.8632 148.847 88.8447 148.906 88.8447C148.965 88.8447 149.023 88.8632 149.072 88.898C149.478 89.2868 149.972 89.548 150.502 89.6538C151.217 89.6538 151.312 88.5592 151.789 85.8486C152.17 83.6072 152.432 82.1476 152.718 80.3493H151.384V80.1668L151.932 79.5153L152.933 79.4631Z" fill="white" />
                <path d="M175.903 76.6496C174.497 76.6496 174.402 76.8841 174.164 78.1873L172.829 84.7031C172.567 85.9802 172.829 86.1365 174.164 86.2408L173.949 86.8402H168.659V86.2408C169.994 86.2408 170.16 85.9802 170.422 84.7031L171.876 78.2133C172.138 76.9102 172.043 76.7799 170.613 76.6756L170.78 76.0762H176.046L175.903 76.6496Z" fill="white" />
                <path d="M188.246 85.1981C187.493 86.1877 186.432 86.8376 185.268 87.0225C184.648 87.0225 184.362 86.6577 184.696 85.0939C184.91 84.0774 185.244 82.8003 185.506 81.7839C185.768 80.7674 185.506 80.7153 185.339 80.7153C185.172 80.7153 183.838 81.6014 183.075 82.8785C182.773 83.3506 182.554 83.8812 182.432 84.4423C182.265 85.146 182.075 85.9539 181.932 86.6577C181.259 86.7389 180.591 86.8607 179.93 87.0225H179.787C180.216 85.3024 180.669 83.504 181.122 81.5753C181.122 81.0801 181.122 80.9498 181.122 80.9498C180.735 81.0692 180.383 81.2933 180.097 81.6014L179.835 81.0801C180.532 80.1774 181.506 79.5843 182.575 79.4121C183.218 79.4121 183.481 80.0376 183.099 81.6535L182.956 82.2009C183.881 80.7421 185.293 79.7402 186.888 79.4121C187.555 79.4121 188.151 79.7249 187.603 82.0184C187.388 82.9046 187.079 84.1817 186.864 85.0157C186.864 85.4587 186.864 85.6412 186.864 85.6412C186.864 85.6412 187.436 85.4327 187.96 84.8593L188.246 85.1981Z" fill="white" />
                <path d="M202.377 85.1449C201.678 86.1475 200.655 86.8191 199.517 87.0214C198.826 87.0214 198.516 86.5262 198.874 84.9364L199.017 84.363C198.102 85.7015 196.772 86.6284 195.276 86.9693C194.418 86.9693 193.894 86.1874 193.894 84.8843C193.888 84.0426 194.062 83.2112 194.401 82.4557C194.74 81.7002 195.235 81.0415 195.848 80.5317C196.863 79.7856 198.036 79.337 199.255 79.2286C199.516 79.1934 199.78 79.1934 200.041 79.2286L200.494 77.1696C200.637 76.3877 200.494 76.3095 199.803 76.2574H199.327V75.71C200.59 75.6058 201.567 75.6058 202.115 75.3452L202.758 75.2148C202.996 75.2148 202.996 75.3973 202.925 75.7361C202.21 78.6812 201.281 82.9035 200.899 84.8321C200.804 85.2491 200.804 85.4576 200.899 85.4576C201.339 85.304 201.728 85.0143 202.019 84.6236L202.377 85.1449ZM198.755 83.6854C199.119 83.2078 199.357 82.6314 199.446 82.0173L199.803 80.5578C199.422 80.2177 198.936 80.0499 198.445 80.0886C196.896 80.0886 196.062 82.5646 196.062 84.5454C196.062 85.197 196.253 85.5098 196.562 85.5098C196.872 85.5098 198.016 84.6497 198.659 83.6854" fill="white" />
                <path d="M211.765 85.1979C211.001 86.1987 209.921 86.8497 208.739 87.0223C208.167 87.0223 207.857 86.5271 208.143 85.3804C208.429 84.2336 208.858 82.6177 209.12 81.497C209.12 81.0278 209.12 80.8715 209.12 80.8715C208.769 80.972 208.443 81.1592 208.167 81.4188L207.857 80.8193C208.698 79.9742 209.753 79.4288 210.884 79.2555C211.479 79.2555 211.622 79.7768 211.241 81.3145C210.979 82.3571 210.598 83.7905 210.336 84.807C210.336 85.224 210.336 85.4586 210.336 85.4586C210.762 85.3024 211.147 85.0338 211.456 84.6767L211.765 85.1979ZM212.075 76.9099C212.069 77.0879 212.031 77.2628 211.963 77.4247C211.895 77.5866 211.798 77.7322 211.679 77.8532C211.559 77.9743 211.419 78.0683 211.266 78.1301C211.114 78.1918 210.951 78.22 210.788 78.213C210.651 78.2405 210.51 78.2339 210.375 78.1936C210.241 78.1533 210.116 78.0804 210.011 77.9803C209.906 77.8801 209.822 77.7553 209.767 77.6151C209.712 77.475 209.686 77.323 209.692 77.1705C209.717 76.8086 209.87 76.4717 210.12 76.2327C210.37 75.9938 210.695 75.8719 211.027 75.8934C211.159 75.8701 211.294 75.8793 211.423 75.9202C211.552 75.9612 211.671 76.0328 211.771 76.1301C211.871 76.2273 211.951 76.3477 212.003 76.4826C212.056 76.6174 212.08 76.7634 212.075 76.9099Z" fill="white" />
                <path d="M225.587 85.1461C224.853 86.1441 223.81 86.812 222.656 87.0227C222.06 87.0227 221.774 86.5535 222.084 84.9897V84.4164C221.556 85.15 220.929 85.7916 220.225 86.319C219.744 86.7445 219.151 86.991 218.533 87.0227C217.795 87.0227 217.223 86.3711 217.223 85.0158C217.245 84.1706 217.436 83.341 217.781 82.5848C218.127 81.8285 218.62 81.1639 219.224 80.6372C220.259 79.7713 221.519 79.2875 222.822 79.2559C223.621 79.3114 224.417 79.4158 225.205 79.5686C224.848 80.8457 224.276 83.3738 224.038 84.7812C224.038 85.1982 224.038 85.4328 224.038 85.4328C224.46 85.2965 224.839 85.0348 225.134 84.677L225.587 85.1461ZM221.917 83.8169C222.248 83.349 222.498 82.8191 222.656 82.2531C222.799 81.5755 223.013 80.7154 223.085 80.2723C222.728 80.1196 222.348 80.0401 221.965 80.0378C220.368 80.0378 219.582 82.8004 219.582 84.5467C219.582 85.2243 219.772 85.511 220.011 85.511C220.249 85.511 221.011 85.0158 221.869 83.8169" fill="white" />
            </g>
            <g clip-path="url(#clip1_1938_4994)">
                <path d="M227.661 7.52876C227.225 6.75731 226.998 5.88595 227 5.00002C226.997 4.12315 227.225 3.26099 227.661 2.50002C228.105 1.73878 228.739 1.10527 229.5 0.660944C230.261 0.22523 231.123 -0.00269949 232 2.41256e-05C232.886 -0.00238045 233.757 0.225361 234.529 0.660944C235.281 1.10711 235.905 1.74076 236.339 2.50002C236.788 3.25543 237.017 4.12137 237 5.00002C237.016 5.88767 236.788 6.76265 236.339 7.52876C235.896 8.27362 235.274 8.89592 234.529 9.3391C233.763 9.78774 232.888 10.0164 232 10C231.121 10.0171 230.255 9.7882 229.5 9.3391C228.747 8.89682 228.115 8.27484 227.661 7.52876ZM228.724 2.87359C228.382 3.48873 228.204 4.18135 228.207 4.88508C228.198 5.58962 228.376 6.28394 228.724 6.89658C229.001 7.59624 229.482 8.19679 230.103 8.62071C230.668 8.98673 231.328 9.17677 232 9.16669C232.672 9.17677 233.333 8.98673 233.897 8.62071C234.494 8.28305 234.975 7.77203 235.276 7.1552C235.624 6.54256 235.802 5.84825 235.793 5.1437C235.843 4.35296 235.664 3.5646 235.276 2.87359C234.942 2.31032 234.467 1.84458 233.897 1.52301C233.333 1.157 232.672 0.966955 232 0.977036C231.328 0.966955 230.668 1.157 230.103 1.52301C229.546 1.8607 229.074 2.32297 228.724 2.87359ZM231.77 5.22991V8.10347H230.305V1.81037H232.517C232.707 1.77822 232.902 1.77822 233.092 1.81037C233.237 1.85741 233.368 1.93983 233.474 2.05019C233.579 2.16055 233.655 2.29539 233.695 2.44255C233.721 2.6044 233.721 2.76921 233.695 2.93106C233.711 3.1319 233.711 3.33367 233.695 3.53451V3.90807V4.28163C233.664 4.42117 233.582 4.54411 233.466 4.62646C233.355 4.71611 233.228 4.78432 233.092 4.82761C233.494 4.82761 233.695 5.1437 233.695 5.77589V8.21841H232.316V5.74715C232.334 5.66186 232.334 5.57382 232.316 5.48853H232.144H231.77M231.77 3.16094V4.36784H232.144C232.316 4.36784 232.431 4.16669 232.431 3.76439C232.493 3.59748 232.493 3.41406 232.431 3.24715C232.261 3.20335 232.083 3.20335 231.914 3.24715" fill="#132328" />
            </g>
            <defs>
                <clipPath id="clip0_1938_4994">
                    <rect width="237" height="19" fill="white" transform="translate(0 73)" />
                </clipPath>
                <clipPath id="clip1_1938_4994">
                    <rect width="10" height="10" fill="white" transform="translate(227)" />
                </clipPath>
            </defs>
        </svg>


    )
}

export default Logo
