import { useEffect, useState, useRef, useCallback } from "react";
import { Button, Grid, Typography } from "@mui/material";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import PageHeader from "src/@core/components/page-header";
import Translations from "src/layouts/components/Translations";
import { ApiEndPoints } from "src/network/endpoints";
import { DefaultPaginationSettings } from "src/constants/general.const";
import { axiosInstance } from "src/network/adapter";
import { toastError, toastSuccess } from "src/utils/utils";
import DialogConfirmation from "src/views/dialog/DialogConfirmation";
import TablePackaging from "src/views/tables/TablePackaging";
import DialogFormAddPackaging from "src/views/dialog/DialogFormAddPackaging";

const PackagingFess = () => {
    const [loading, setLoading] = useState(false);

    const [packagingData, setPackagingData] = useState([]);
    const [search, setSearch] = useState("");
    const [totalCount, setTotalCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(
        DefaultPaginationSettings.ROWS_PER_PAGE
    );

    const [packagingFormDialogOpen, setPackagingFormDialogOpen] = useState(false);
    const [packagingFormDailogMode, setPackagingFormDailogMode] = useState("add");
    const [packagingToEdit, setPackagingToEdit] = useState(null);

    const togglePackagingFormDialog = (e, mode = "add", packagingToEdit = null) => {
        setPackagingFormDialogOpen((prev) => !prev);
        setPackagingFormDailogMode(mode);
        setPackagingToEdit(packagingToEdit);
    };
    const [confirmationDialogLoading, setConfirmationDialogLoading] =
        useState(false);
    const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
    const [packagingToDelete, setPackagingToDelete] = useState(null);

    const toggleConfirmationDialog = (e, dataToDelete = null) => {
        setConfirmationDialogOpen((prev) => !prev);
        setPackagingToDelete(dataToDelete);
    };

    const fetchData = ({
        currentPage,
        pageSize = DefaultPaginationSettings.ROWS_PER_PAGE,
        search,
    }) => {
        setLoading(true);
        let params = {
            pages: currentPage,
            limits: pageSize,
            search: search,
        };
        if (params.pages === undefined && params.search === undefined) return;

        axiosInstance
            .get(ApiEndPoints.Packaging_Fees.list, { params })
            .then((response) => {
                setPackagingData(response.data.data.packagingData);
                setTotalCount(response.data.data.totalData);
                console.log("Packaging Data response--------------------", response.data);
            })
            .catch((error) => {
                toastError(error);
            })
            .finally(() => {
                setLoading(false);
            });
    };
    useEffect(() => {
        fetchData({
            currentPage: currentPage,
            pageSize: pageSize,
            search: search,
        });
    }, [currentPage, pageSize, search]);

    const onConfirmDeletepackaging = useCallback(
        (e) => {
            e?.preventDefault();
            setConfirmationDialogLoading(true);
            axiosInstance
                .delete(ApiEndPoints.Packaging_Fees.delete(packagingToDelete._id))
                .then((response) => response.data)
                .then((response) => {
                    fetchData({
                        currentPage: currentPage,
                        pageSize: pageSize,
                        search: search,
                    });
                    toggleConfirmationDialog();
                    toastSuccess(response.message);
                    console.log("response", response);
                })
                .catch((error) => {
                    toastError(error);
                })
                .finally(() => {
                    setConfirmationDialogLoading(false);
                });
        },
        [currentPage, packagingToDelete, pageSize, search]
    );

    return (
        <>
            <Grid container spacing={4} className="match-height">
                <PageHeader
                    title={
                        <Typography variant="h5">
                            <Translations text="Packaging Fees" />
                        </Typography>
                    }
                    action={
                        <Button variant="contained" onClick={togglePackagingFormDialog}
                            disabled={packagingData?.length === 1}
                        >
                            Add Packaging Fees
                        </Button>
                    }
                />
                <Grid item xs={12}>
                    <Card>
                        <Box
                            sx={{
                                p: 5,
                                pb: 0,
                                display: "flex",
                                flexWrap: "wrap",
                                alignItems: "center",
                                justifyContent: "space-between",
                            }}
                        >
                            <Box></Box>
                        </Box>
                        <Box>
                            <Box sx={{ p: 5 }}>
                                <TablePackaging
                                    search={search}
                                    loading={loading}
                                    rows={packagingData}
                                    totalCount={totalCount}
                                    setCurrentPage={setCurrentPage}
                                    currentPage={currentPage}
                                    setPageSize={setPageSize}
                                    pageSize={pageSize}
                                    toggleEdit={togglePackagingFormDialog}
                                    toggleDelete={toggleConfirmationDialog}
                                />
                            </Box>
                        </Box>
                    </Card>
                </Grid>
            </Grid>

            <DialogFormAddPackaging
                mode={packagingFormDailogMode}
                open={packagingFormDialogOpen}
                toggle={togglePackagingFormDialog}
                dataToEdit={packagingToEdit}
                onSuccess={() => {
                    fetchData({
                        currentPage: currentPage,
                        pageSize: pageSize,
                    });
                }}
            />

            <DialogConfirmation
                loading={confirmationDialogLoading}
                title="Delete Packaging Fees"
                subtitle="Are you sure you want to delete this Packaging Fees?"
                open={confirmationDialogOpen}
                toggle={toggleConfirmationDialog}
                onConfirm={onConfirmDeletepackaging}
            />
        </>
    );
};

export default PackagingFess;
