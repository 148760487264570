import { useEffect, useState, useRef, useCallback } from "react";
import { Button, Grid, Typography } from "@mui/material";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import PageHeader from "src/@core/components/page-header";
import Translations from "src/layouts/components/Translations";
import TableUserRole from "src/views/tables/TableUserRole";
import { ApiEndPoints } from "src/network/endpoints";
import { DefaultPaginationSettings } from "src/constants/general.const";
import { axiosInstance } from "src/network/adapter";
import { toastError, toastSuccess } from "src/utils/utils";
import DialogConfirmation from "src/views/dialog/DialogConfirmation";
import RestaurantMenu from "../../../src/pages/restuarant-menu";
import { useNavigate } from "react-router-dom";
import DialogRestaurantViewDetail from "src/views/dialog/DialogRestaurantViewDetail";
import DialogRestaurantStatus from "src/views/dialog/DialogRestaurantStatus";
import DialogFormRoleManagement from "src/views/dialog/DialogFormAddRoleManagement";

const RoleManagement = () => {
  const searchTimeoutRef = useRef();

  const [restaurantData, setRestaurantData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(
    DefaultPaginationSettings.ROWS_PER_PAGE
  );

  const [selectedRestaurantId, setSelectedRestaurantId] = useState(null);

  const [restaurantFormDialogOpen, setRestaurantFormDialogOpen] =
    useState(false);
  const [restaurantFormDialogMode, setRestaurantFormDialogMode] =
    useState("add");
  const [restaurantToEdit, setRestaurantToEdit] = useState(null);

  const toggleRestaurantFormDialog = (
    e,
    mode = "edit",
    rowId,
    rowToEdit = null
  ) => {
    setRestaurantFormDialogOpen((prev) => !prev);
    setRestaurantFormDialogMode(mode);
    setRestaurantToEdit(rowToEdit);
    setSelectedRestaurantId(rowId); // Set the selected restaurant id
    console.log("Row_Id___________", rowId);
  };

  // Confirmation
  const [confirmationDialogLoading, setConfirmationDialogLoading] =
    useState(false);
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
  const [restaurantToDelete, setRestuarantToDelete] = useState(null);

  const [viewDetailDialogLoading, setViewDetailDialogLoading] = useState(false);
  const [viewDetailDialogOpen, setViewDetailDialogOpen] = useState(false);
  const [dataToView, setDataToView] = useState(null);

  const toggleConfirmationDialog = (e, dataToDelete = null) => {
    setConfirmationDialogOpen((prev) => !prev);
    setRestuarantToDelete(dataToDelete);
  };
  const toggleViewDetailDialog = (e, dataToView = null) => {
    setViewDetailDialogOpen((prev) => !prev);
    setDataToView(dataToView);
  };

  const fetchData = ({
    currentPage,
    pageSize = DefaultPaginationSettings.ROWS_PER_PAGE,
    search,
  }) => {
    setLoading(true);
    let params = {
      pages: currentPage,
      limits: pageSize,
      search: search,
    };
    if (params.pages === undefined && params.search === undefined) return;

    axiosInstance
      .get(ApiEndPoints.USERROLE.list, { params })
      .then((response) => {
        // setRestaurantData(response.data.data.restaurantData);
        setRestaurantData(
          response.data.data.UserList.map((row, index) => ({
            ...row,
            id: index + 1,
          }))
        );

        setTotalCount(response.data.data.totalData);
      })
      .catch((error) => {
        toastError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchData({
      currentPage: currentPage,
      pageSize: pageSize,
      search: search,
    });
  }, [currentPage, pageSize, search]);

  const onConfirmDeleteRestaurant = useCallback(
    (e) => {
      console.log("restaurantToDelete:", restaurantToDelete);
      e?.preventDefault();
      setConfirmationDialogLoading(true);
      axiosInstance
        .delete(ApiEndPoints.USERROLE.delete(restaurantToDelete._id))
        .then((response) => response.data)
        .then((response) => {
          fetchData({
            currentPage: currentPage,
            pageSize: pageSize,
            search: search,
          });
          toggleConfirmationDialog();
          toastSuccess(response.message);
          console.log("response", response);
        })
        .catch((error) => {
          toastError(error);
        })
        .finally(() => {
          setConfirmationDialogLoading(false);
        });
    },
    [currentPage, restaurantToDelete, pageSize, search]
  );

  return (
    <>
      <Grid container spacing={4} className="match-height">
        <PageHeader
          title={
            <Typography variant="h5">
              <Translations text="Role Management" />
            </Typography>
          }
          action={
            <Button
              variant="contained"
              onClick={(e) => toggleRestaurantFormDialog(e, "add")}
            >
              Add New User
            </Button>
          }
        />
        <Grid item xs={12}>
          <Card>
            <Box
              sx={{
                p: 5,
                pb: 0,
                display: "flex",
                flexWrap: "wrap",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Box></Box>
            </Box>
            <Box>
              <Box sx={{ p: 5 }}>
                <TableUserRole
                  search={search}
                  loading={loading}
                  rows={restaurantData}
                  totalCount={totalCount}
                  setCurrentPage={setCurrentPage}
                  currentPage={currentPage}
                  setPageSize={setPageSize}
                  pageSize={pageSize}
                  toggleEdit={toggleRestaurantFormDialog}
                  toggleDelete={toggleConfirmationDialog}
                  toggleView={toggleViewDetailDialog}
                  // onCellClick={(_id) => handleCellClick(_id)}
                />
              </Box>
              {/* {selectedRestaurantId && <RestaurantMenu restaurantId={selectedRestaurantId} />} */}
            </Box>
          </Card>
        </Grid>
      </Grid>

      <DialogFormRoleManagement
        mode={restaurantFormDialogMode}
        open={restaurantFormDialogOpen}
        toggle={toggleRestaurantFormDialog}
        dataToEdit={restaurantToEdit}
        onSuccess={() => {
          fetchData({
            currentPage: currentPage,
            pageSize: pageSize,
          });
        }}
        rowId={selectedRestaurantId} // Pass the selected restaurant id as rowId
      />

      <DialogRestaurantViewDetail
        loading={viewDetailDialogLoading}
        dataToEdit={dataToView}
        open={viewDetailDialogOpen}
        toggle={toggleViewDetailDialog}
        title="Restaurant Detail"
        onSuccess={() => {
          fetchData({
            currentPage: currentPage,
            pageSize: pageSize,
          });
        }}
        rowId={selectedRestaurantId}
      />

      <DialogConfirmation
        loading={confirmationDialogLoading}
        title="Delete User"
        subtitle="Are you sure you want to delete this User?"
        open={confirmationDialogOpen}
        toggle={toggleConfirmationDialog}
        onConfirm={onConfirmDeleteRestaurant}
      />
    </>
  );
};

export default RoleManagement;
