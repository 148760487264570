import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Button,
    Typography,
    Box,
    IconButton,
    FormControl,
    FormLabel,
    FormHelperText,
    Grid,
    TextField,
    MenuItem,
    Select,
    InputAdornment,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import ClearIcon from '@mui/icons-material/Clear';

import { LoadingButton } from "@mui/lab";

import "react-quill/dist/quill.snow.css";
import * as yup from "yup";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate } from "react-router-dom";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import toast from "react-hot-toast";
import { axiosInstance } from "src/network/adapter";
import { ApiEndPoints } from "src/network/endpoints";
import authConfig from "../../configs/auth";

const schema = yup.object({
    password: yup.string().required("Enter Current Password"),
    new_password: yup
        .string()
        .required("Enter New Password")
        .min(8, "Password must be at least 8 characters"),
    confirm_password: yup
        .string("Enter Valid Confirm Password Format")
        .required("Enter Confirm Password")
        .oneOf([yup.ref("new_password"), null], "Passwords must match")
        .min(8),
});

const DialogChangePassword = (props) => {
    const { open, toggle, onSuccess } = props;

    const [password, setPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const navigate = useNavigate();
    const [showPassword, setShowPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const token = window.localStorage.getItem(authConfig.storageTokenKeyName);
    const handleTogglePassword = () => {
        setShowPassword(!showPassword);
    };
    const handleToggleNewPassword = () => {
        setShowNewPassword(!showNewPassword);
    };
    const handleToggleConfirmPassword = () => {
        setShowConfirmPassword(!showConfirmPassword);
    };
    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm({
        defaultValues: {
            password: "",
            new_password: "",
        },
        resolver: yupResolver(schema),
    });
    const handleChangePassword = async (e) => {
        try {
            const response = await axiosInstance.patch(
                ApiEndPoints.AUTH.change_password,
                {
                    password: password,
                    new_password: newPassword,
                },
                {
                    headers: { Authorization: `Bearer ${token}` },
                }
            );
            toast.success(response?.message);
            toggle();
            reset();
        } catch (error) {
            console.log("error", error)
            toast.error(error?.message);
        }
    };
    useEffect(() => {
        if (!open) {
            reset(); // Reset the form when the dialog closes
        }
    }, [open, reset]);
    return (
        <>
            <Dialog
                open={open}
                onClose={toggle}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                sx={{
                    "& .MuiDialog-paper": {
                        width: "408px",
                        maxWidth: "none", // Prevents default maxWidth styles from overriding your custom width
                        borderRadius: "20px",
                        p: 1,
                    },
                }}
            >
                <DialogTitle
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                    }}
                >
                    <Box>
                        <Typography
                            sx={{
                                fontSize: "20px",
                                fontWeight: 600,
                                color: "#101828",
                                lineHeight: "30px",
                            }}
                            gutterBottom
                        >
                            Change Password
                        </Typography>
                        <Typography
                            sx={{
                                fontSize: "14px",
                                fontWeight: 500,
                                color: "#667085",
                                lineHeight: "20px",
                            }}
                        >
                            Create a new Password
                        </Typography>
                    </Box>

                    <IconButton aria-label="close" onClick={toggle}>
                        <ClearIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <form
                        id="change-password-form"
                        onSubmit={handleSubmit(handleChangePassword)}
                    >
                        <Grid container spacing={4}>
                            <Grid item xs={12}>
                                <FormControl fullWidth>
                                    <FormLabel
                                        htmlFor="password"
                                        sx={{ color: "#111828", fontWeight: 500 }}
                                    >
                                        Current Password
                                    </FormLabel>
                                    <TextField
                                        id="password"
                                        placeholder="Enter Current Password"
                                        inputProps={{
                                            style: {
                                                color: "#646465",
                                                fontWeight: 500,
                                                fontSize: "16px",
                                            },
                                        }}
                                        {...register("password")}
                                        onChange={(e) => {
                                            setPassword(e.target.value);
                                        }}
                                        type={showPassword ? "text" : "password"}
                                        autoComplete="current-password"
                                        fullWidth
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        onClick={handleTogglePassword}
                                                        edge="end"
                                                    >
                                                        {showPassword ? (
                                                            <VisibilityOutlinedIcon fontSize="small" />
                                                        ) : (
                                                            <VisibilityOffOutlinedIcon fontSize="small" />
                                                        )}
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />

                                    <Typography variant="body2" color="error">
                                        {errors.password?.message}
                                    </Typography>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl fullWidth>
                                    <FormLabel
                                        htmlFor="new_password"
                                        sx={{ color: "#111828", fontWeight: 500 }}
                                    >
                                        New Password
                                    </FormLabel>
                                    <TextField
                                        id="new_password"
                                        placeholder="Enter New Password"
                                        inputProps={{
                                            style: {
                                                color: "#646465",
                                                fontWeight: 500,
                                                fontSize: "16px",
                                            },
                                        }}
                                        {...register("new_password")}
                                        onChange={(e) => {
                                            setNewPassword(e.target.value);
                                        }}
                                        type={showNewPassword ? "text" : "password"}
                                        autoComplete="current-password"
                                        fullWidth
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        onClick={handleToggleNewPassword}
                                                        edge="end"
                                                    >
                                                        {showNewPassword ? (
                                                            <VisibilityOutlinedIcon fontSize="small" />
                                                        ) : (
                                                            <VisibilityOffOutlinedIcon fontSize="small" />
                                                        )}
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                    <Typography variant="body2" color="error">
                                        {errors.new_password?.message}
                                    </Typography>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl fullWidth>
                                    <FormLabel
                                        htmlFor="confirm_password"
                                        sx={{ color: "#111828", fontWeight: 500 }}
                                    >
                                        Confirm Password
                                    </FormLabel>
                                    <TextField
                                        id="confirm_password"
                                        placeholder="Confirm Your Password"
                                        inputProps={{
                                            style: {
                                                color: "#646465",
                                                fontWeight: 500,
                                                fontSize: "16px",
                                            },
                                        }}
                                        {...register("confirm_password")}
                                        onChange={(e) => {
                                            setNewPassword(e.target.value);
                                        }}
                                        type={showConfirmPassword ? "text" : "password"}
                                        autoComplete="current-password"
                                        fullWidth
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        onClick={handleToggleConfirmPassword}
                                                        edge="end"
                                                    >
                                                        {showConfirmPassword ? (
                                                            <VisibilityOutlinedIcon fontSize="small" />
                                                        ) : (
                                                            <VisibilityOffOutlinedIcon fontSize="small" />
                                                        )}
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                    <Typography variant="body2" color="error">
                                        {errors.confirm_password?.message}
                                    </Typography>
                                </FormControl>
                            </Grid>
                        </Grid>
                    </form>
                </DialogContent>
                <DialogActions
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                    }}
                >
                    <Button
                        size="large"
                        variant="outlined"
                        onClick={toggle}
                        sx={{
                            borderRadius: "8px",
                            border: "1px solid #D0D5DD",
                            color: "#344054",
                        }}
                    >
                        Cancel
                    </Button>
                    <LoadingButton
                        size="large"
                        type="submit"
                        form="change-password-form"
                        variant="contained"
                        // loading={loading}
                        sx={{ borderRadius: "8px" }}
                    >
                        Save
                    </LoadingButton>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default DialogChangePassword;
