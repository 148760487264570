import { yupResolver } from '@hookform/resolvers/yup'
import { LoadingButton } from '@mui/lab'
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormHelperText,
    FormLabel,
    Grid,
    IconButton,
    MenuItem,
    Select,
    TextField
} from '@mui/material'
import CloseIcon from 'mdi-material-ui/Close'
import { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { axiosInstance } from 'src/network/adapter'
import { ApiEndPoints } from 'src/network/endpoints'
import * as yup from 'yup'
import { toastError, toastSuccess } from 'src/utils/utils'
import { useNavigate, useParams } from 'react-router-dom'

const validationSchema = yup.object().shape({
    admin_message: yup.string().required('Required'),
    status: yup.string().required('Required')
})

const DialogFormGetInTouchStatus = props => {
    const { open, toggle, dataToEdit, onSuccess } = props
    const [loading, setLoading] = useState(false)
    const {
        control,
        handleSubmit,
        reset,
        formState: { errors }
    } = useForm({
        defaultValues: {
            admin_message: dataToEdit?.message || '',
            status: dataToEdit?.status || ''
        },
        resolver: yupResolver(validationSchema),
        mode: 'onChange'
    })

    useEffect(() => {
        if (open) {
            setLoading(false)
            reset({
                admin_message: dataToEdit?.message || '',
                status: dataToEdit?.status || ''
            })
        }
    }, [dataToEdit, open, reset])
    console.log('dataToEdit feedback', dataToEdit)

    const onSubmit = data => {
        const PayloadData = {
            admin_message: data?.admin_message,
            status: data?.status
        }

        setLoading(true)
        axiosInstance
            .patch(ApiEndPoints.Vendor_Support.update_status(dataToEdit._id), PayloadData)
            .then(response => {
                onSuccess()
                toastSuccess(response.data.message)
                toggle()
            })
            .catch(error => {
                toastError(error)
            })
            .finally(() => {
                setLoading(false)
            })
    }
    return (
        <>
            <Dialog open={open} onClose={toggle} fullWidth maxWidth='sm' scroll='paper'>
                <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Box>Edit Status</Box>
                    <IconButton aria-label='close' onClick={toggle} sx={{ color: theme => theme.palette.grey[500] }}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent sx={{ pb: 8, px: { sx: 8, sm: 15 }, pt: { xs: 8, sm: 12.5 } }}>
                    <form id='status-form' onSubmit={handleSubmit(onSubmit)}>
                        <Grid container spacing={4}>
                            <Grid item xs={12}>
                                <FormControl fullWidth>
                                    <FormLabel htmlFor='admin_message'>
                                        Message
                                    </FormLabel>
                                    <Controller
                                        name='admin_message'
                                        control={control}
                                        render={({ field: { value, onChange } }) => (
                                            <TextField
                                                id='admin_message'
                                                value={value}
                                                onChange={onChange}
                                                error={Boolean(errors.admin_message)}
                                            />
                                        )}
                                    />
                                    {errors.admin_message && (
                                        <FormHelperText sx={{ color: 'error.main' }}>{errors.admin_message.message}</FormHelperText>
                                    )}
                                </FormControl>
                            </Grid>

                            <Grid item xs={12}>
                                <FormControl fullWidth>
                                    <FormLabel htmlFor='status' error={Boolean(errors.status)}>
                                        Status
                                    </FormLabel>
                                    <Controller
                                        name='status'
                                        control={control}
                                        render={({ field: { value, onChange } }) => (
                                            <Select
                                                id='status'
                                                value={value}
                                                onChange={onChange}
                                                error={Boolean(errors.status)}
                                            >
                                                <MenuItem value={'Pending'}>pending</MenuItem>
                                                <MenuItem value={'Resolved'}>resolved</MenuItem>
                                            </Select>
                                        )}
                                    />
                                    {errors.status && (
                                        <FormHelperText sx={{ color: 'error.main' }}>{errors.status.message}</FormHelperText>
                                    )}
                                </FormControl>
                            </Grid>
                        </Grid>
                    </form>
                </DialogContent>
                <DialogActions>
                    <LoadingButton size='large' type='submit' form='status-form' variant='contained' loading={loading}>
                        Submit
                    </LoadingButton>
                    <Button size='large' variant='outlined' onClick={toggle}>
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default DialogFormGetInTouchStatus
