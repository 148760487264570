import Typography from "@mui/material/Typography";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import CustomDataGrid from "src/@core/components/data-grid";
import { useNavigate } from "react-router-dom";
import moment from "moment";

function TableOrder({
    rows,
    totalCount,
    setCurrentPage,
    currentPage,
    setPageSize,
    pageSize,
    loading,
    toggleEdit,
    toggleDelete,
    toggleView,
    onCellClick
}) {

    // const handleCellClick = (_id) => {
    //     onCellClick(_id);
    // };

    // Reusable cell renderer function
    // const renderCellWithClick = (fieldName) => ({ row }) => (
    //     <Typography
    //         noWrap
    //         variant='body2'
    //         title={row.fieldName}
    //         onClick={() => handleCellClick(row._id)}
    //         style={{ cursor: 'pointer' }}
    //     >
    //         {row[fieldName]}
    //     </Typography>
    // );
    const navigate = useNavigate()
    const handleViewDetails = (orderId) => {
        navigate(`/Orders/${orderId}`);
    };
    console.log("row", rows)
    return <CustomDataGrid
        loading={loading}
        rowCount={totalCount}
        rows={rows}
        columns={[
            {
                field: 'sr.no',
                headerName: 'Sr.No',
                minWidth: 20,
                align: 'left',
                renderCell: ({ row }) => {
                    return <div style={{ textDecoration: 'none', color: 'rgba(58, 53, 65, 0.87)' }}>
                        {row.id}
                    </div >
                }
            },
            {
                field: 'order_id',
                headerName: 'Order Id',
                minWidth: 220,
                align: 'left',
                renderCell: ({ row }) => {
                    return <div style={{ textDecoration: 'none', color: 'rgba(58, 53, 65, 0.87)' }}>
                        {row._id}
                    </div >
                }
            },
            {
                field: 'order_date',
                headerName: 'Order Date',
                minWidth: 150,
                align: 'left',
                renderCell: ({ row }) => {
                    return <div style={{ textDecoration: 'none', color: 'rgba(58, 53, 65, 0.87)' }}>
                        {moment(row?.create_order_time).format('LL')}
                    </div >
                }
            },
            {
                field: 'order_status',
                headerName: 'Order Status',
                minWidth: 180,
                align: 'left',
                renderCell: ({ row }) => {
                    return <div style={{ textDecoration: 'none', color: 'rgba(58, 53, 65, 0.87)' }}>
                        {row?.status}
                    </div >
                }
            },
            {
                field: 'customer_name',
                flex: 0.2,
                minWidth: 150,
                sortable: false,
                headerName: 'Name',
                // renderCell: renderCellWithClick('name')
            },
            {
                field: 'delivery_address',
                minWidth: 150,
                flex: 0.5,
                sortable: false,
                headerName: 'Address',
            },
            {
                field: 'delivery_state',
                flex: 0.2,
                minWidth: 170,
                sortable: false,
                headerName: 'State',
                // renderCell: renderCellWithClick('country_code')
            },

            {
                field: 'actions',
                flex: 0,
                minWidth: 170,
                sortable: false,
                headerName: 'Actions',
                renderCell: ({ row }) => <Box display='flex' alignItems='center' gap='10px'>
                    {/* <IconButton
                        size="small"
                        color="primary"
                        variant="outlined"
                        onClick={(e) => toggleEdit(e, "edit", row._id, row)} // Pass row._id as a parameter
                    >
                        <EditIcon />
                    </IconButton> */}
                    {/* <IconButton size="small" color="secondary" onClick={(e) => toggleDelete(e, row)}>
                        <DeleteIcon />
                    </IconButton> */}
                    <IconButton size="small" color="secondary" onClick={() => navigate(`/Orders/${row._id}`)}>
                        <RemoveRedEyeIcon />
                    </IconButton>
                </Box>
            }
        ]}
        currentPage={currentPage}
        pageSize={pageSize}
        setCurrentPage={setCurrentPage}
        setPageSize={setPageSize}
    />

}

export default TableOrder
