import React, { useCallback, useEffect, useState } from "react";
import { Button, Grid, Paper, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import PageHeader from "src/@core/components/page-header";
import { DefaultPaginationSettings } from "src/constants/general.const";
import { ApiEndPoints } from "src/network/endpoints";
import { axiosInstance } from "src/network/adapter";
import { toastError, toastSuccess } from "src/utils/utils";
import Translations from "src/layouts/components/Translations";
import TableMenuRestaurant from "src/views/tables/TableMenuRestaurant";
import DialogFormAddMenuRestaurant from "src/views/dialog/DailogFormAddMenuRestaurant";
import DialogConfirmation from "src/views/dialog/DialogConfirmation";

const RestaurantPage = () => {
  const [loading, setLoading] = useState(false);

  const [restaurantMenuData, setRestaurantMenuData] = useState([]);
  // const [search, setSearch] = useState("");
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(
    DefaultPaginationSettings.ROWS_PER_PAGE
  );

  // const [selectedRestaurantId, setSelectedRestaurantId] = useState(null);

  const [restaurantFormDialogOpen, setRestaurantFormDialogOpen] =
    useState(false);
  const [restaurantFormDialogMode, setRestaurantFormDialogMode] =
    useState("add");
  const [restaurantToEdit, setRestaurantToEdit] = useState(null);

  const toggleRestaurantFormDialog = (
    e,
    mode = "add",
    restaurantToEdit = null
  ) => {
    setRestaurantFormDialogOpen((prev) => !prev);
    setRestaurantFormDialogMode(mode);
    setRestaurantToEdit(restaurantToEdit);
  };

  const [confirmationDialogLoading, setConfirmationDialogLoading] =
    useState(false);
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
  const [restaurantToDelete, setReatuarantToDelete] = useState(null);
  const toggleConfirmationDialog = (e, dataToDelete = null) => {
    setConfirmationDialogOpen((prev) => !prev);
    setReatuarantToDelete(dataToDelete);
  };

  const fetchData = ({
    currentPage,
    pageSize = DefaultPaginationSettings.ROWS_PER_PAGE,
  }) => {
    setLoading(true);
    let params = {
      pages: currentPage,
      limits: pageSize,
    };
    if (params.pages === undefined) return;

    axiosInstance
      .get(ApiEndPoints.Restaurant_Menu.list, { params })
      .then((response) => {
        setRestaurantMenuData(response.data.data.menuData);
        setTotalCount(response.data.data.menuData.length);
        console.log("response--------------------", response);
      })
      .catch((error) => {
        toastError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchData({
      currentPage: currentPage,
      pageSize: pageSize,
      // search: search,
    });
  }, [currentPage, pageSize]);

  const onConfirmDeleteRestaurant = useCallback(
    (e) => {
      e?.preventDefault();
      setConfirmationDialogLoading(true);
      axiosInstance
        .patch(ApiEndPoints.Restaurant_Menu.delete(restaurantToDelete._id))
        .then((response) => response.data)
        .then((response) => {
          fetchData({
            currentPage: currentPage,
            pageSize: pageSize,
            // search: search,
          });
          toggleConfirmationDialog();
          toastSuccess(response.message);
          console.log("response", response);
        })
        .catch((error) => {
          toastError(error);
        })
        .finally(() => {
          setConfirmationDialogLoading(false);
        });
    },
    [currentPage, restaurantToDelete, pageSize]
  );

  return (
    <>
      <Grid container spacing={4} className="match-height">
        <PageHeader
          title={
            <Typography variant="h5">
              <Translations text="Restaurant-Menu" />
            </Typography>
          }
          action={
            <Button variant="contained" onClick={toggleRestaurantFormDialog}>
              Add Menu
            </Button>
          }
        />
        <Grid item xs={12}>
          <Paper>
            <Box
              sx={{
                p: 5,
                pb: 0,
                display: "flex",
                flexWrap: "wrap",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <TableMenuRestaurant
                // search={search}
                loading={loading}
                rows={restaurantMenuData}
                totalCount={totalCount}
                setCurrentPage={setCurrentPage}
                currentPage={currentPage}
                setPageSize={setPageSize}
                pageSize={pageSize}
                toggleEdit={toggleRestaurantFormDialog}
                toggleDelete={toggleConfirmationDialog}
              />
            </Box>
          </Paper>
          <DialogFormAddMenuRestaurant
            mode={restaurantFormDialogMode}
            open={restaurantFormDialogOpen}
            toggle={toggleRestaurantFormDialog}
            dataToEdit={restaurantToEdit}
            onSuccess={() => {
              fetchData({
                currentPage: currentPage,
                pageSize: pageSize,
              });
            }}
          />
          <DialogConfirmation
            loading={confirmationDialogLoading}
            title="Delete Menu"
            subtitle="Are you sure you want to delete this Restaurant Menu?"
            open={confirmationDialogOpen}
            toggle={toggleConfirmationDialog}
            onConfirm={onConfirmDeleteRestaurant}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default RestaurantPage;
