import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, FormHelperText, FormLabel, Grid, IconButton, Radio, RadioGroup, TextField } from "@mui/material"
import CloseIcon from "mdi-material-ui/Close";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { CleaveNumberInput } from "src/@core/components/cleave-components";
import { axiosInstance } from "src/network/adapter";
import { ApiEndPoints } from "src/network/endpoints";
import * as yup from 'yup'
import { toastError, toastSuccess } from "src/utils/utils";
import "react-datepicker/dist/react-datepicker.css";

const validationSchema = yup.object().shape({
    packaging_fees: yup.number()
        .typeError("Must be a number")
        .positive("Must be a Positive Number")
        .required("Gst Amount Required"),
});


const DialogFormAddPackaging = (props) => {

    const { mode, open, toggle, dataToEdit, onSuccess } = props;

    const [loading, setLoading] = useState(false);
    const [dialogTitle, setDialogTitle] = useState('');

    const {
        control,
        handleSubmit,
        reset,
        formState: { errors }
    } = useForm({
        defaultValues: {
            packaging_fees: '',
        },
        resolver: yupResolver(validationSchema),
        mode: 'onChange'
    })

    useEffect(() => {
        if (open) {
            setLoading(false);
            reset({
                packaging_fees: dataToEdit?.packaging_fees || '',
            })
            setDialogTitle(mode === "add" ? "Add Packaging Fees " : "Edit Packaging Fees ")
        }
    }, [dataToEdit, mode, open, reset])

    const onSubmit = (data) => {
        let payload = {
            packaging_fees: data.packaging_fees,
        }
        setLoading(true);

        let apiInstance = null;

        if (mode === "edit") {
            apiInstance = axiosInstance
                .patch(ApiEndPoints.Packaging_Fees.edit(dataToEdit._id), payload)
        } else {
            apiInstance = axiosInstance
                .post(ApiEndPoints.Packaging_Fees.create, payload)
        }
        apiInstance
            .then((response) => response.data)
            .then((response) => {
                onSuccess();
                toastSuccess(response.message);
                toggle();
            })
            .catch((error) => {
                toastError(error)
            })
            .finally(() => {
                setLoading(false);
            })
    }
    console.log("submitted", dataToEdit);

    return <>
        <Dialog open={open} onClose={toggle} fullWidth maxWidth='sm' scroll="paper">
            <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Box>{dialogTitle}</Box>
                <IconButton
                    aria-label="close"
                    onClick={toggle}
                    sx={{ color: (theme) => theme.palette.grey[500] }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent sx={{ pb: 8, px: { sx: 8, sm: 15 }, pt: { xs: 8, sm: 12.5 } }}>
                <form id="Gst-form" onSubmit={handleSubmit(onSubmit)}>
                    <Grid container spacing={4}>
                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <FormLabel htmlFor='packaging_fees' error={Boolean(errors.packaging_fees)}>Packaging Fees Amount</FormLabel>
                                <Controller
                                    name='packaging_fees'
                                    control={control}
                                    render={({ field: { value, onChange } }) => (
                                        <TextField
                                            value={value}
                                            onChange={onChange}
                                            type="text"
                                            placeholder='Enter Packaging Fees Amount'
                                            error={Boolean(errors.packaging_fees)}
                                            InputLabelProps={{ shrink: true }}
                                        />
                                    )}
                                />
                                {errors.packaging_fees && (
                                    <FormHelperText sx={{ color: 'error.main' }}>
                                        {errors.packaging_fees.message}
                                    </FormHelperText>
                                )}
                            </FormControl>
                        </Grid>
                    </Grid>
                </form>
            </DialogContent>
            <DialogActions>
                <LoadingButton
                    size="large"
                    type="submit"
                    form="Gst-form"
                    variant="contained"
                    loading={loading}
                >
                    Submit
                </LoadingButton>
                <Button size="large" variant="outlined" onClick={toggle}>
                    Cancel
                </Button>
            </DialogActions>
        </Dialog >
    </>
}

export default DialogFormAddPackaging