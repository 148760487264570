import { yupResolver } from '@hookform/resolvers/yup'
import { LoadingButton } from '@mui/lab'
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormHelperText,
    FormLabel,
    Grid,
    IconButton,
    MenuItem,
    Select
} from '@mui/material'
import CloseIcon from 'mdi-material-ui/Close'
import { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { axiosInstance } from 'src/network/adapter'
import { ApiEndPoints } from 'src/network/endpoints'
import * as yup from 'yup'
import { toastError, toastSuccess } from 'src/utils/utils'
import { useNavigate, useParams } from 'react-router-dom'

const validationSchema = yup.object().shape({
    account_status: yup.string().required('Required')
})

const DialogRestaurantStatus = props => {
    const { open, toggle, dataToEdit, onSuccess } = props
    const [loading, setLoading] = useState(false)
    const {
        control,
        handleSubmit,
        reset,
        formState: { errors }
    } = useForm({
        defaultValues: {
            account_status: dataToEdit?.account_status || '',
        },
        resolver: yupResolver(validationSchema),
        mode: 'onChange'
    })

    useEffect(() => {
        if (open) {
            setLoading(false)
            reset({
                account_status: dataToEdit?.account_status || ''
            })
        }
    }, [dataToEdit, open, reset])
    console.log('dataToEdit', dataToEdit)
    const statusOptions = [
        { value: 'active', label: 'Active' },
        { value: 'inactive', label: 'Deactivate' },
        { value: 'banned', label: 'Banned' },
    ];

    const onSubmit = data => {
        const PayloadData = {
            account_status: data.account_status
        }

        setLoading(true)
        axiosInstance
            .patch(ApiEndPoints.Restaurant.update_status(dataToEdit._id), PayloadData)
            .then(response => {
                onSuccess()
                toastSuccess(response.data.message)
                toggle()
            })
            .catch(error => {
                toastError(error)
            })
            .finally(() => {
                setLoading(false)
            })
    }
    return (
        <>
            <Dialog open={open} onClose={toggle} fullWidth maxWidth='sm' scroll='paper'>
                <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Box>Edit Restaurant's Status</Box>
                    <IconButton aria-label='close' onClick={toggle} sx={{ color: theme => theme.palette.grey[500] }}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent sx={{ pb: 8, px: { sx: 8, sm: 15 }, pt: { xs: 8, sm: 12.5 } }}>
                    <form id='status-form' onSubmit={handleSubmit(onSubmit)}>
                        <Grid container spacing={4}>
                            <Grid item xs={12}>
                                <FormControl fullWidth>
                                    <FormLabel htmlFor='account_status' error={Boolean(errors.account_status)}>
                                        Restaurant's Status
                                    </FormLabel>
                                    <Controller
                                        name='account_status'
                                        control={control}
                                        render={({ field: { value, onChange } }) => (
                                            <Select
                                                id='account_status'
                                                value={value}
                                                onChange={onChange}
                                                error={Boolean(errors.account_status)}
                                            >
                                                {statusOptions.map(option => (
                                                    <MenuItem key={option.value} value={option.value}>
                                                        {option.label}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        )}
                                    />
                                    {errors.account_status && (
                                        <FormHelperText sx={{ color: 'error.main' }}>{errors.account_status.message}</FormHelperText>
                                    )}
                                </FormControl>
                            </Grid>
                        </Grid>
                    </form>
                </DialogContent>
                <DialogActions>
                    <LoadingButton size='large' type='submit' form='status-form' variant='contained' loading={loading}>
                        Submit
                    </LoadingButton>
                    <Button size='large' variant='outlined' onClick={toggle}>
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default DialogRestaurantStatus
