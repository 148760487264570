import { Box, Dialog, DialogContent, DialogTitle, Grid, IconButton, Typography } from '@mui/material'
import CloseIcon from 'mdi-material-ui/Close'
import moment from 'moment'

const DialogRestaurantViewDetail = props => {
    const { open, toggle, dataToEdit } = props

    return (
        <Dialog open={open} fullWidth maxWidth='md' scroll='paper'>
            <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Box> <Typography fontWeight={600} fontSize={'20px'}>Restuarant Detail</Typography></Box>
                <IconButton aria-label='close' onClick={toggle} sx={{ color: theme => theme.palette.grey[500] }}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent sx={{ pb: 8, px: { sx: 8, sm: 15 }, pt: { xs: 8, sm: 12.5 } }}>
                <Grid container spacing={4}>
                    <Grid item xs={12}>
                        <Typography textTransform={'capitalize'}>
                            <span style={{ fontWeight: 600 }}>Name :</span>{dataToEdit?.name}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography><span style={{ fontWeight: 600 }}>Description : </span>{dataToEdit?.description}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography><span style={{ fontWeight: 600 }}>Restaurant Category :</span>{dataToEdit?.restaurant_catagory}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography><span style={{ fontWeight: 600 }}>Address :</span> {dataToEdit?.address}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography><span style={{ fontWeight: 600 }}>Email :</span>  {dataToEdit?.email}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography><span style={{ fontWeight: 600 }}>Phone Number :</span> {dataToEdit?.country_code}{dataToEdit?.phone_number}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography textTransform={'capitalize'}><span style={{ fontWeight: 600 }}>Start Time :</span> {moment(dataToEdit?.start_date_and_time).format('MMMM Do YYYY, h:mm:ss a')}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography textTransform={'capitalize'}><span style={{ fontWeight: 600 }}>End Time :</span> {moment(dataToEdit?.end_date_and_time).format('MMMM Do YYYY, h:mm:ss a')}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography><span style={{ fontWeight: 600 }}>Ratings :</span> </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography>
                            <span style={{ fontWeight: 600 }}>Latitude : </span>{dataToEdit?.coordinatesArray[0] || ''}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography>
                            <span style={{ fontWeight: 600 }}>Longitude : </span> {dataToEdit?.coordinatesArray[1] || ''}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography>
                            <span style={{ fontWeight: 600 }}>Image : </span>
                        </Typography>
                        <img src={dataToEdit?.profile_image} style={{ width: "100px", height: "100px" }} />
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog >
    )
}

export default DialogRestaurantViewDetail
