import Typography from "@mui/material/Typography";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import CustomDataGrid from "src/@core/components/data-grid";
import { Link } from "react-router-dom";

function TableRestaurantTags({
    rows,
    totalCount,
    setCurrentPage,
    currentPage,
    setPageSize,
    pageSize,
    loading,
    toggleEdit,
    toggleDelete,
    onCellClick
}) {
    const handleCellClick = (restaurantId) => {
        onCellClick(restaurantId);
    };

    return <CustomDataGrid
        loading={loading}
        rowCount={totalCount}
        rows={rows}
        columns={[
            {
                field: 'tag_name',
                flex: 0.3,
                minWidth: 150,
                sortable: false,
                headerName: 'Tag Name',
                renderCell: ({ row }) => <Typography noWrap variant='body2' title={row.name} onClick={() => handleCellClick(row.id)} >
                    {row.tag_name}
                </Typography>
            },

            {
                field: 'tag_images',
                flex: 0.3,
                minWidth: 150,
                sortable: false,
                headerName: 'Tag Images',
                renderCell: ({ row }) => (
                    <Box
                        sx={{ display: "flex", justifyContent: "center", width: '50px', height: '50px', cursor: 'pointer' }}
                    >
                        <img
                            src={row.tag_images}
                            alt={row.tag_images}
                            style={{ objectFit: "contain" }}
                        />
                    </Box>
                ),
            },

            {
                field: 'actions',
                flex: 0,
                minWidth: 170,
                sortable: false,
                headerName: 'Actions',
                renderCell: ({ row }) => <Box display='flex' alignItems='center' gap='10px'>
                    <IconButton size="small" color="primary" variant="outlined" onClick={(e) => toggleEdit(e, "edit", row)}>
                        <EditIcon />
                    </IconButton>
                    <IconButton size="small" color="secondary" onClick={(e) => toggleDelete(e, row)}>
                        <DeleteIcon />
                    </IconButton>
                </Box>
            }
        ]}
        currentPage={currentPage}
        pageSize={pageSize}
        setCurrentPage={setCurrentPage}
        setPageSize={setPageSize}
    />

}

export default TableRestaurantTags
