import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  FormLabel,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import CloseIcon from "mdi-material-ui/Close";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { axiosInstance } from "src/network/adapter";
import { ApiEndPoints } from "src/network/endpoints";
import * as yup from "yup";
import { toastError, toastSuccess } from "src/utils/utils";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import DialogConfirmation from "./DialogConfirmation";
import CustomFileUploads from "../common/customFileUploads";

const validationSchema = yup.object().shape({
  name: yup.string().required("Required"),
  country_code: yup
    .string("Must be positive numbers only")
    .required("Required")
    .matches(/^\d+$/, "Country code must be numeric only"),
  phone_number: yup
    .string("Must be positive number only")
    .required("Required")
    .typeError("Must be positive Number only"),
  email: yup
    .string()
    .required("Email is required")
    .email("Invalid email address"),
  password: yup
    .string()
    .min(6, "Password must be at least 6 characters")
    .required("Password is required"),
  restaurant_tag: yup
    .array()
    .of(yup.string())
    .min(1, "At least one tag is required")
    .required("At least one tag is required"),
});

const DialogFormRoleManagement = (props) => {
  const [inputValue, setInputValue] = useState("");
  const [categories, setCategories] = useState([]);
  const data = [
    { _id: "12231324345", tag_name: "Customer support" },
    { _id: "12232432445", tag_name: "Account Management" },
    { _id: "12231324345", tag_name: "Manager / Restaurant" },
    { _id: "11245324345", tag_name: "Administrator" },
  ];
  const [restaurantTagsData, setRestaurantTagsData] = useState(data);
  const [selectedOption, setSelectedOption] = useState([]);
  const { mode, open, toggle, dataToEdit, onSuccess, rowId } = props;
  console.log("restaurantTagsData", restaurantTagsData);
  const [loading, setLoading] = useState(false);
  const [dialogTitle, setDialogTitle] = useState("");
  const {
    control,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: "",
      country_code: "",
      phone_number: "",
      email: "",
      password: "",
      role: "",
    },
    resolver: yupResolver(validationSchema),
    mode: "onChange",
  });

  useEffect(() => {
    console.log("role",dataToEdit?.role)
    if (open) {
      setLoading(false);
      reset({
        name: dataToEdit?.name || "",
        country_code: dataToEdit?.country_code,
        phone_number: dataToEdit?.phone_number,
        email: dataToEdit?.email || "",
        restaurant_tag: dataToEdit?.restaurant_tag || "",
      });
      setSelectedOption(dataToEdit?.restaurant_tag || "");
      setDialogTitle(mode === "add" ? "Add New User" : "Edit New User");

      // Set categories state based on dataToEdit
      setCategories(dataToEdit?.restaurant_catagory || []);
    }
  }, [dataToEdit, mode, open, reset]);

  const onSubmit = (data) => {
    const payload = new FormData();
    const newPayload = {
      name: data.name,
      country_code: `+${data.country_code}`,
      phone_number: data.phone_number,
      email: data.email,
      role: data.restaurant_tag[0],
      password: data.password,
    };

    // Append non-file fields to the FormData
    payload.append("name", data.name);
    // payload.append("description", data.description);

    // Append array items individually
    // categories.forEach((category, index) => {
    //   payload.append(`restaurant_catagory[${index}]`, category);
    // });

    // Append other fields
    // payload.append("address", data.address);
    payload.append("country_code", `+${data.country_code}`);
    payload.append("phone_number", data.phone_number);
    // payload.append(
    //   "start_date_and_time",
    //   new Date(data.start_date_and_time).toISOString()
    // );
    // payload.append(
    //   "end_date_and_time",
    //   new Date(data.end_date_and_time).toISOString()
    // );
    payload.append("email", data.email);
    payload.append("password", data.password);
    data?.restaurant_tag?.forEach((tag, index) => {
      // payload.append(`restaurant_tag[${index}]`, tag);
      payload.append(`role`, tag);
    });

    // Check if file is present and append
    // if (data.profile_image) {
    //   payload.append("profile_image", data.profile_image);
    // } else {
    //   console.error("No file uploaded");
    //   return; // Exit the function if no file is uploaded
    // }

    console.log("payload entries:");
    for (let pair of payload.entries()) {
      console.log(pair[0] + ": " + pair[1]);
    }

    setLoading(true);

    let apiInstance = null;
    console.log(newPayload);
    if (mode === "edit" && dataToEdit && dataToEdit._id) {
      apiInstance = axiosInstance.patch(
        ApiEndPoints.USERROLE.edit(dataToEdit._id),
        newPayload,
        {
          // headers: {
          //   "Content-Type": "multipart/form-data",
          // },
        }
      );
    } else {
      apiInstance = axiosInstance.post(ApiEndPoints.USERROLE.create, newPayload, {
        // headers: {
        //   "Content-Type": "multipart/form-data",
        // },
      });
    }
    apiInstance
      .then((response) => response.data)
      .then((response) => {
        onSuccess();
        toastSuccess(response.message);
        toggle();
      })
      .catch((error) => {
        toastError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleAddCategory = () => {
    if (inputValue.trim() !== "") {
      const trimmedValue = inputValue.trim();
      console.log("Adding category:", trimmedValue);
      setCategories((prevCategories) => [...prevCategories, trimmedValue]);
      setInputValue("");
    }
  };
  useEffect(() => {
    console.log("Categories after update:", categories);
  }, [categories]);

  const [confirmationDialogLoading, setConfirmationDialogLoading] =
    useState(false);
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
  const [categoryToDelete, setCategoryToDelete] = useState(null);

  // Memoized categories to prevent unnecessary re-renders
  const memoizedCategories = useMemo(() => categories, [categories]);

  const toggleConfirmationDialog = (indexToRemove) => {
    setCategoryToDelete(indexToRemove);
    setConfirmationDialogOpen((prev) => !prev);
  };

  const onConfirmDeleteRestaurant = useCallback(
    (e, indexToRemove) => {
      e?.preventDefault();
      console.log("rowId", rowId);
      setConfirmationDialogLoading(true);
      const categoryToDelete = categories[indexToRemove];
      axiosInstance
        .patch(ApiEndPoints.Restaurant.delete_category(rowId), {
          data: { categoryName: categoryToDelete },
        })
        .then((response) => response.data)
        .then((response) => {
          toggleConfirmationDialog();
          toastSuccess(response.message);
          setCategories((prevCategories) => {
            const newCategories = [...prevCategories];
            newCategories.splice(indexToRemove, 1);
            return newCategories;
          });
          console.log("response", response);
        })
        .catch((error) => {
          toastError(error);
        })
        .finally(() => {
          setConfirmationDialogLoading(false);
        });
    },
    [categories, rowId]
  );

  const fetchData = () => {
    setLoading(true);

    axiosInstance
      .get(ApiEndPoints.Restaurant_Tags.dropdown_list)
      .then((response) => {
        console.log("Tags", response);
        // setRestaurantTagsData(
        //   response?.data.data.map((row, index) => ({
        //     ...row,
        //     id: index + 1,
        //   }))
        // );

        console.log("debug response--------------------", response);
      })
      .catch((error) => {
        toastError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <Dialog
        open={open}
        onClose={toggle}
        fullWidth
        maxWidth="sm"
        scroll="paper"
      >
        <DialogTitle
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box>{dialogTitle}</Box>
          <IconButton
            aria-label="close"
            onClick={toggle}
            sx={{ color: (theme) => theme.palette.grey[500] }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent
          sx={{ pb: 8, px: { sx: 8, sm: 15 }, pt: { xs: 8, sm: 12.5 } }}
        >
          <form id="restaurant-form" onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <FormLabel htmlFor="name" error={Boolean(errors.name)}>
                    Name
                  </FormLabel>
                  <Controller
                    name="name"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <TextField
                        value={value}
                        onChange={onChange}
                        type="text"
                        placeholder="Enter Restaurant Name"
                        error={Boolean(errors.name)}
                        InputLabelProps={{ shrink: true }}
                      />
                    )}
                  />
                  {errors.name && (
                    <FormHelperText sx={{ color: "error.main" }}>
                      {errors.name.message}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <FormControl fullWidth>
                  <FormLabel
                    htmlFor="country_code"
                    error={Boolean(errors.country_code)}
                  >
                    Country Code
                  </FormLabel>
                  <Controller
                    name="country_code"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <TextField
                        value={value}
                        onChange={(e) => {
                          const numericValue = e.target.value.replace(
                            /\D/g,
                            ""
                          );
                          onChange(numericValue);
                        }}
                        type="text"
                        placeholder="Enter Country Code"
                        error={Boolean(errors.country_code)}
                        InputLabelProps={{ shrink: true }}
                      />
                    )}
                  />
                  {errors.country_code && (
                    <FormHelperText sx={{ color: "error.main" }}>
                      {errors.country_code.message}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <FormLabel
                    htmlFor="phone_number"
                    error={Boolean(errors.phone_number)}
                  >
                    Phone Number
                  </FormLabel>
                  <Controller
                    name="phone_number"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <TextField
                        value={value}
                        onChange={(e) => {
                          const numericValue = e.target.value.replace(
                            /\D/g,
                            ""
                          );
                          onChange(numericValue);
                        }}
                        type="text"
                        placeholder="Enter Phone Number"
                        error={Boolean(errors.phone_number)}
                        InputLabelProps={{ shrink: true }}
                      />
                    )}
                  />
                  {errors.phone_number && (
                    <FormHelperText sx={{ color: "error.main" }}>
                      {errors.phone_number.message}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <FormControl fullWidth>
                  <FormLabel htmlFor="email" error={Boolean(errors.email)}>
                    Email
                  </FormLabel>
                  <Controller
                    name="email"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <TextField
                        value={value}
                        onChange={onChange}
                        type="text"
                        placeholder="Enter Email"
                        error={Boolean(errors.email)}
                        InputLabelProps={{ shrink: true }}
                      />
                    )}
                  />
                  {errors.email && (
                    <FormHelperText sx={{ color: "error.main" }}>
                      {errors.email.message}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <FormLabel
                    htmlFor="password"
                    error={Boolean(errors.password)}
                  >
                    Password
                  </FormLabel>
                  <Controller
                    name="password"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <TextField
                        value={value}
                        onChange={onChange}
                        type="text"
                        placeholder="Enter Password"
                        error={Boolean(errors.password)}
                        InputLabelProps={{ shrink: true }}
                      />
                    )}
                  />
                  {errors.password && (
                    <FormHelperText sx={{ color: "error.main" }}>
                      {errors.password.message}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <FormControl fullWidth>
                  <FormLabel
                    htmlFor="restaurant_tag"
                    error={Boolean(errors.restaurant_tag)}
                  >
                    Select Role
                  </FormLabel>
                  <Controller
                    name="restaurant_tag"
                    control={control}
                    render={({ field: { onChange } }) => (
                      <Autocomplete
                        multiple
                        options={restaurantTagsData}
                        value={selectedOption || []} // Ensure value is always an array
                        onChange={(event, newValue) => {
                          setSelectedOption(newValue || []); // Set to an empty array if null
                          setValue(
                            "restaurant_tag",
                            (newValue || []).map((tag) => tag.tag_name)
                          );
                        }}
                        getOptionLabel={(option) => option?.tag_name || ""} // Null check
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            type="text"
                            placeholder="Select Role"
                            error={Boolean(errors.restaurant_tag)}
                            InputLabelProps={{ shrink: true }}
                          />
                        )}
                      />
                    )}
                  />
                  {errors.restaurant_tag && (
                    <FormHelperText sx={{ color: "error.main" }}>
                      {errors.restaurant_tag.message}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
            </Grid>
            <Grid item container xs={12} md={12} display={"flex"} py={3}>
              {memoizedCategories.map((category, index) => (
                <Grid item p={1} key={category}>
                  <Paper
                    elevation={3}
                    sx={{
                      p: 2,
                      mb: 1,
                      display: "flex",
                      alignItems: "center",
                      bgcolor: "#03577B",
                    }}
                  >
                    <Typography variant="body1" color="#FFFFFF" pl={2}>
                      {category}
                    </Typography>
                    <IconButton
                      onClick={() => toggleConfirmationDialog(index)}
                      size="small"
                      sx={{ ml: 1, color: "#FFFFFF" }}
                    >
                      <DeleteIcon sx={{ ":hover": { color: "#fc6030" } }} />
                    </IconButton>
                  </Paper>
                </Grid>
              ))}
            </Grid>
          </form>
        </DialogContent>
        <DialogActions>
          <LoadingButton
            size="large"
            type="submit"
            form="restaurant-form"
            variant="contained"
            loading={loading}
          >
            Submit
          </LoadingButton>
          <Button size="large" variant="outlined" onClick={toggle}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      <DialogConfirmation
        loading={confirmationDialogLoading}
        title="Delete Restaurant Category"
        subtitle={
          <Typography variant="subtitle1">
            Are you sure you want to delete the category "
            <strong>{categories[categoryToDelete]}</strong>"?
          </Typography>
        }
        open={confirmationDialogOpen}
        toggle={() => toggleConfirmationDialog(null)} // Pass null here
        onConfirm={(e) => onConfirmDeleteRestaurant(e, categoryToDelete)} // Pass categoryToDelete here
      />
    </>
  );
};

export default DialogFormRoleManagement;
