import Typography from "@mui/material/Typography";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import CustomDataGrid from "src/@core/components/data-grid";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { Avatar, FormControl, FormLabel, Grid, InputLabel, MenuItem, Select, TextField, Tooltip } from "@mui/material";
import { GridToolbar } from "@mui/x-data-grid";
import { useState } from "react";
import { Controller } from "react-hook-form";
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";

function TableReports({
    rows,
    totalCount,
    setCurrentPage,
    currentPage,
    setPageSize,
    pageSize,
    loading,
    orderIds,
    selectedOrderId,
    setSelectedOrderId,
    setStartDate,
    setEndDate,
    control,
    toggleEdit,
    toggleDelete,
    toggleView,
    onCellClick
}) {
    const navigate = useNavigate();
    const handleViewDetails = (restaurantId) => {
        navigate(`/restaurant/${restaurantId}`);
    };

    const handleOrderIdChange = (newOrderId) => {
        setSelectedOrderId(newOrderId?.target?.value);
    };

    return (
        <>
            {/* Date Pickers */}
            <Grid container spacing={3} sx={{ mb: 10 }}>
                <Grid item xs={12} sm={4}>
                    <FormControl fullWidth>
                        <FormLabel htmlFor="start_date" sx={{ fontSize: "1rem", fontWeight: 500 }}>
                            Start Date
                        </FormLabel>
                        <Controller
                            name="start_date"
                            control={control}
                            render={({ field: { value, onChange } }) => (
                                <DatePicker
                                    selected={value}
                                    dateFormat="MM/dd/yyyy"
                                    onChange={(date) => {
                                        onChange(date);
                                        const formattedDate = moment(date).format('YYYY-MM-DD');
                                        setStartDate(formattedDate);
                                    }}
                                    minDate={new Date()}
                                    maxTime={moment().endOf('day').toDate()}
                                    customInput={
                                        <TextField
                                            variant="outlined"
                                            placeholder="MM/dd/yyyy"
                                            fullWidth
                                            sx={{
                                                '& .MuiOutlinedInput-root': {
                                                    height: '40px',  // Set height on the root input
                                                    padding: '0 14px',  // Adjust padding
                                                },
                                                '& input': {
                                                    padding: '10px 0', // Adjust padding inside the input
                                                }
                                            }}
                                        />
                                    }
                                />
                            )}
                        />
                    </FormControl>
                </Grid>

                <Grid item xs={12} sm={4}>
                    <FormControl fullWidth>
                        <FormLabel htmlFor="end_date" sx={{ fontSize: "1rem", fontWeight: 500 }}>
                            End Date
                        </FormLabel>
                        <Controller
                            name="end_date"
                            control={control}
                            render={({ field: { value, onChange } }) => (
                                <DatePicker
                                    selected={value}
                                    dateFormat="MM/dd/yyyy"
                                    onChange={(date) => {
                                        onChange(date);
                                        const formattedDate = moment(date).format('YYYY-MM-DD');
                                        setEndDate(formattedDate);
                                    }}
                                    minDate={new Date()}
                                    maxTime={moment().endOf('day').toDate()}
                                    customInput={
                                        <TextField
                                            variant="outlined"
                                            placeholder="MM/dd/yyyy"
                                            fullWidth
                                            sx={{
                                                '& .MuiOutlinedInput-root': {
                                                    height: '40px',  // Set height on the root input
                                                    padding: '0 14px',  // Adjust padding
                                                },
                                                '& input': {
                                                    padding: '10px 0', // Adjust padding inside the input
                                                }
                                            }}
                                        />
                                    }
                                />
                            )}
                        />
                    </FormControl>
                </Grid>

                <Grid item xs={12} sm={4}>
                    <FormControl fullWidth>
                        <FormLabel htmlFor="orderId" sx={{ fontSize: "1rem", fontWeight: 500 }}>
                            Select Order ID
                        </FormLabel>
                        <Controller
                            name="orderId"
                            control={control}
                            render={({ field: { value, onChange } }) => (
                                <Select
                                    id="order-id-select"
                                    value={selectedOrderId}
                                    onChange={handleOrderIdChange}
                                    sx={{
                                        minWidth: 200,
                                        height: "40px !important",
                                        borderRadius: "8px",
                                        '& .MuiOutlinedInput-root': {
                                            '& fieldset': {
                                                borderColor: 'rgba(0, 0, 0, 0.23)',
                                            },
                                            '&:hover fieldset': {
                                                borderColor: 'primary.main',
                                            },
                                        },
                                    }}
                                >
                                    {orderIds?.map((order) => (
                                        <MenuItem key={order.orderId} value={order.orderId}>
                                            {order.orderId}
                                        </MenuItem>
                                    ))}
                                </Select>
                            )}
                        />
                    </FormControl>

                </Grid>
            </Grid>

            {/* Order ID Selection */}


            {/* DataGrid */}
            <CustomDataGrid
                type="report"
                loading={loading}
                rowCount={totalCount}
                rows={rows}
                columns={[
                    {
                        field: 'sr.no',
                        headerName: 'Sr.No',
                        minWidth: 230,
                        align: 'left',
                        valueGetter: (params) => params.row._id,
                        renderCell: ({ row }) => (
                            <Typography variant="body2" sx={{ color: 'rgba(58, 53, 65, 0.87)' }}>
                                {row?._id}
                            </Typography>
                        )
                    },
                    {
                        field: 'user_name',
                        minWidth: 170,
                        sortable: false,
                        headerName: 'Customer Name',
                        valueGetter: (params) => params.row?.customerId?.user_name,
                        renderCell: ({ row }) => (
                            <Typography variant="body2" sx={{ color: 'rgba(58, 53, 65, 0.87)' }}>
                                {row?.customerId?.user_name}
                            </Typography>
                        )
                    },
                    {
                        field: 'phone_number',
                        flex: 0.2,
                        minWidth: 170,
                        sortable: false,
                        headerName: 'Phone Number',
                        valueGetter: (params) =>
                            `${params.row?.customerId?.country_code || ''} ${params.row?.customerId?.phone_number || ''
                            }`,
                        renderCell: ({ row }) => (
                            <Typography noWrap title={row?.customerId?.phone_number} sx={{ cursor: 'pointer', color: "#3a3541de", fontSize: "0.875rem" }}>
                                {row?.customerId?.country_code} {row?.customerId?.phone_number}
                            </Typography>
                        )
                    },
                    {
                        field: 'profile_img',
                        flex: 0.2,
                        minWidth: 170,
                        sortable: false,
                        headerName: 'Profile Img',
                        valueGetter: (params) => params.row?.customerId?.profile_img,
                        renderCell: ({ row }) => (
                            <Avatar src={row?.customerId?.profile_img} />
                        )
                    },
                    {
                        field: 'name',
                        minWidth: 170,
                        sortable: false,
                        headerName: 'Restaurant Name',
                        valueGetter: (params) => params.row?.restaurantId?.name,
                        renderCell: ({ row }) => (
                            <Typography variant="body2" sx={{ color: 'rgba(58, 53, 65, 0.87)' }}>
                                {row?.restaurantId?.name}
                            </Typography>
                        )
                    },
                    {
                        field: 'email',
                        minWidth: 200,
                        sortable: false,
                        headerName: 'Email',
                        valueGetter: (params) => params.row?.restaurantId?.email,
                        renderCell: ({ row }) => (
                            <Typography variant="body2" sx={{ color: 'rgba(58, 53, 65, 0.87)' }}>
                                {row?.restaurantId?.email}
                            </Typography>
                        )
                    },
                    {
                        field: 'description',
                        flex: 0.3,
                        minWidth: 200,
                        sortable: false,
                        headerName: 'Description',
                        valueGetter: (params) => params.row?.restaurantId?.description,
                        renderCell: ({ row }) => (
                            <Typography variant="body2" sx={{ color: 'rgba(58, 53, 65, 0.87)' }}>
                                {row?.restaurantId?.description}
                            </Typography>
                        )
                    },
                    // {
                    //     field: 'actions',
                    //     flex: 0,
                    //     minWidth: 170,
                    //     sortable: false,
                    //     headerName: 'Actions',
                    //     renderCell: ({ row }) => (
                    //         <Box display="flex" alignItems="center" gap="10px">
                    //             <Tooltip title="View Details">
                    //                 <IconButton size="small" color="primary" onClick={() => handleViewDetails(row?._id)}>
                    //                     <RemoveRedEyeIcon />
                    //                 </IconButton>
                    //             </Tooltip>
                    //         </Box>
                    //     )
                    // }
                ]}
                currentPage={currentPage}
                pageSize={pageSize}
                setCurrentPage={setCurrentPage}
                setPageSize={setPageSize}
            />
        </>
    );
}

export default TableReports;
